<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">Roles</h2>
						<div class="breadcrumb-wrapper">
							<ol class="breadcrumb">
								<li class="breadcrumb-item active">
									<span aria-current="location"> Roles </span>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Table Container Card -->
		<b-card no-body>
			<div class="m-2 d-flex justify-content-between">
				<div>
					<h3 class="content-header mb-0">Role List</h3>
				</div>
				<b-button v-b-modal.add-role variant="primary">
					<feather-icon icon="PlusIcon" size="16" /> Add Role
				</b-button>
			</div>
			<b-table
				:items="roles"
				responsive
				:fields="fields"
				primary-key="id"
				show-empty
				empty-text="No matching records found"
				class="position-relative"
			>
				<template #cell(index)="data">
					<div class="ml-1">
						{{ currentPage - 1 + data.index + 1 }}
					</div>
				</template>

				<template #cell(actions)="data">
					<div class="text-nowrap" v-if="data.item.access_level == null">
						<feather-icon
							icon="EditIcon"
							size="16"
							v-b-modal.edit-role
							@click="() => getRoleById(data.item.id)"
						/>

						<b-tooltip title="Edit" :target="`invoice-row-${data.item.id}-preview-icon`" />

						<feather-icon
							@click="() => setRoleId(data.item.id)"
							icon="TrashIcon"
							size="16"
							class="mx-1"
							v-b-modal.delete-role
						/>

						<b-tooltip title="View" :target="`invoice-row-${data.item.id}-preview-icon`" />
					</div>
				</template>
			</b-table>

			<!-- add role modal -->

			<b-modal
				id="add-role"
				ok-only
				title="Add Role"
				ok-title="Submit"
				@ok="addRole"
				ref="my-modal"
				@hidden="resetForm"
			>
				<validation-observer ref="simpleRules">
					<b-form ref="form" @submit="addRole">
						<b-form-group label="Enter Role Name" label-for="Enter Role Name">
							<validation-provider #default="{ errors }" name="Role Name" rules="required">
								<b-form-input
									name="role_name"
									v-model="formData.name"
									placeholder="Enter Role Name"
									maxlength="30"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-form>
				</validation-observer>
			</b-modal>

			<!-- edit role modal -->
			<b-modal id="edit-role" ok-only title="Edit Role" ok-title="Submit" @ok="updateRole" ref="my-modal">
				<validation-observer ref="simpleRules">
					<b-form ref="form" @submit="updateRole">
						<b-form-group label="Enter Role Name" label-for="Enter Role Name">
							<validation-provider #default="{ errors }" name="Role Name" rules="required">
								<b-form-input
									name="role_name"
									v-model="formData.name"
									placeholder="Enter Role Name"
									maxlength="30"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-form>
				</validation-observer>
			</b-modal>

			<!-- delete modal -->
			<DeleteModal id="delete-role" :onClicked="deleteRole" />
		</b-card>
	</div>
</template>

<script>
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import useRoleList from "./useRoleList";
import { required, alpha } from "@validations";
import roleStoreModules from "../roleStoreModules";
import axiosIns from "@/libs/axios";
import moment from "moment";
import DeleteModal from "@core/components/modal/Delete-modal.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
	components: {
		required,
		alpha,
		vSelect,
		DeleteModal,
	},
	data() {
		return {
			selected: null,
			roles: [],
			currentPage: 1,
			formData: {
				name: null,
			},
			userData: {},
			fields: [
				{ key: "index", label: "S.no" },
				"name",
				{
					key: "created_at",
					label: "created at",
					sortable: true,
					formatter: (value) => {
						return moment(value).format("MMMM Do YYYY");
					},
				},
				{ key: "actions", label: "Actions" },
			],
		};
	},
	methods: {
		getCompanyRoles() {
			var url = "";
			if (this.userData.roles[0] === "super_admin") {
				url = `web/get-default-role`;
			} else {
				url = `web/roles`;
			}
			axiosIns
				.get(url)
				.then((response) => {
					this.roles = response.data.companyRoles;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		addRole() {
			var url = "";
			if (this.userData.roles[0] === "super_admin") {
				url = `web/add-default-role`;
			} else {
				url = `web/roles`;
			}
			var formData = new FormData();
			let form_data = this.objectToFormData(this.formData, formData);
			axiosIns
				.post(url, form_data)
				.then((response) => {
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "success",
							text: `Role Successfully Added `,
						},
					});
					this.getCompanyRoles();
				})
				.catch((error) => {
					const data = error.response.data.message;

					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: data,
						},
					});
					this.getCompanyRoles();
				});
		},

		setRoleId(id) {
			this.formData.id = id;
		},

		getRoleById(id) {
			axiosIns
				.get(`web/roles/${id}`)
				.then((response) => {
					this.formData = response.data.role;
					this.formData.id = id;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		updateRole() {
			var formData = new FormData();
			formData.append("_method", "PUT");
			let form_data = this.objectToFormData(this.formData, formData);
			axiosIns
				.post(`web/roles/${this.formData.id}`, form_data)
				.then((response) => {
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "success",
							text: `Role Successfully Updated `,
						},
					});
					this.$refs["my-modal"].hide();
					this.getCompanyRoles();
				})
				.catch((error) => {
					const data = error.response.data.message;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: data,
						},
					});
					this.getCompanyRoles();
				});
		},

		deleteRole(id) {
			var data = new FormData();
			data.append("_method", "DELETE");
			axiosIns
				.post(`web/roles/${this.formData.id}`, data)
				.then((response) => {
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: `Role Successfully Deleted `,
						},
					});
					this.getCompanyRoles();
				})
				.catch((error) => {
					const data = error.response.data.message;

					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: data,
						},
					});
					this.getCompanyRoles();
				});
		},

		resetForm() {
			this.formData.name = "";
		},
	},
	created() {
		this.userData = JSON.parse(localStorage.getItem("userData"));
		this.getCompanyRoles();
	},
	setup() {
		const INVOICE_APP_STORE_MODULE_NAME = "app-invoice";

		// Register module
		if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
			store.registerModule(INVOICE_APP_STORE_MODULE_NAME, roleStoreModules);

		// UnRegister on leave
		onUnmounted(() => {
			if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
		});

		const statusOptions = ["Downloaded", "Draft", "Paid", "Partial Payment", "Past Due"];

		const {
			fetchInvoices,
			saleTable,
			perPage,
			currentPage,
			totalInvoices,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refInvoiceListTable,
			statusFilter,
			refetchData,
			resolveInvoiceStatusVariantAndIcon,
			resolveClientAvatarVariant,
		} = useRoleList();

		return {
			fetchInvoices,
			saleTable,
			perPage,
			currentPage,
			totalInvoices,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refInvoiceListTable,

			statusFilter,

			refetchData,

			statusOptions,

			avatarText,
			resolveInvoiceStatusVariantAndIcon,
			resolveClientAvatarVariant,
		};
	},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
.header-title {
	height: 60px;
	border-bottom: 1px solid #ebe9f1;
	font-weight: 500;
	color: #636363;
	font-size: 1.714rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem;
}

.invoice-filter-select {
	min-width: 190px;

	::v-deep .vs__selected-options {
		flex-wrap: nowrap;
	}

	::v-deep .vs__selected {
		width: 100px;
	}
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
